import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20221111-Rate-Sheet.pdf";
const date = "11.11.2022";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>
        <h1 className="page-title">Changes to our mortgage products</h1>

        <br />
        <p>With effect from Friday 11 November 2022 we will be making the following changes to our range:</p>

        <p><strong>Summary of changes</strong></p>

        <p><strong>UK Existing Customer Switching Range</strong></p>
        <ul>
        <li>3 Year Fixed Standard at 70%, 75% and 80% LTV have reduced</li>
        <li>5 Year Fixed Fee Saver at 90% LTV has reduced</li>
        </ul>

        <p><strong>UK Existing Customer Borrowing More Range</strong></p>
        <ul>
        <li>3 Year Fixed Standard at 70%, 75% and 80% LTV have reduced</li>
        <li>5 Year Fixed Fee Saver at 90% LTV has reduced</li>
        </ul>

        <p><strong>UK First Time Buyer Range</strong></p>
        <ul>
        <li>5 Year Fixed Fee Saver at 80% LTV has reduced</li>
        </ul>

        <p><strong>UK Home Mover Range</strong></p>
        <ul>
        <li>5 Year Fixed Fee Saver at 80% LTV has reduced</li>
        </ul>

        <p><strong>UK Remortgage Range</strong></p>
        <ul>
        <li>5 Year Fixed Fee Saver at 80% and 85% LTV have reduced</li>
        </ul>
        <p>There are no other changes to any other rates or to fixed rate end dates.</p>
        <p>An updated mortgage rate sheet will be published on Friday 11 November to reflect these changes.</p>
      
  </NewsArticleLayout>
);

export default Article;
